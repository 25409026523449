import React, { useEffect, useState } from 'react';
import { Navigation } from './components/Navigation';
import Footer from './components/footer';
import './SofiaOpen.css';
import { HeaderBigImage } from './components/HeaderImage';
import { HeaderElement, HeaderMenu } from './components/HeaderMenu';
import { DocumentIcon, InformationIcon, LivestreamIcon, NewsIcon, PhotoIcon, RegistrationIcon, ResultsIcon, ScheduleIcon } from './images/Icons';
import axios from 'axios';
import { Section } from './components/Section';
import { InformationElement } from './components/Information';
import { DocumentsPerDay } from './components/Documents';
import { Registration } from './components/Registration';
import { NewsElement } from './components/NewsElement';

export default function SofiaOpen() {
    document.title = '12. Ramus Sofia Open';

    const [getInfo, setInfo] = useState([]);
    useEffect(() => {
        async function fetchInfo() {
            try {
                const res = await axios.get('https://www.ramustkd.org/assets/json/open?file=info');
                setInfo(res.data);
            } catch (error) {
                console.log(error);
                setInfo([]);
            }
        }
        fetchInfo();
    }, []);
    const [getLive, setLive] = useState([]);
    useEffect(() => {
        async function fetchLive() {
            try {
                const res = await axios.get('https://www.ramustkd.org/assets/json/open?file=live');
                setLive(res.data);
            } catch (error) {
                console.log(error);
                setLive([]);
            }
        }
        fetchLive();
    }, []);
    const [getDocs, setDocs] = useState([]);
    useEffect(() => {
        async function fetchDocs() {
            try {
                const res = await axios.get('https://www.ramustkd.org/assets/json/open?file=docs');
                setDocs(res.data);
            } catch (error) {
                console.log(error);
                setDocs([]);
            }
        }
        fetchDocs();
    }, []);

    const [event, setEvent] = useState<any>(null);
    const [eventGotParticipants, setEventGotParticipants] = useState(false);
    const [eventInfo, setEventInfo] = useState<any>(null);
    const fetchEvent = async () => {
        try {
            const res = await axios.get('https://www.ramustkd.org/assets/json/open?file=participants');
            setEvent(res.data);
            setEventGotParticipants(true);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchEventInfo = async (eventLink: string) => {
        try {
            const res = await axios.get(`http://129.152.5.66:8081/api/event?eventLink=${eventLink}`);
            setEventInfo(res.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchEvent();
    }, []);
    useEffect(() => {
        if (eventGotParticipants && event?.eventLink) fetchEventInfo(event.eventLink);
    }, [eventGotParticipants, event]);

    return (
        <>
            <header>
                <Navigation />
                <HeaderBigImage
                    info1='22 - 23 March 2025'
                    info2='Sofia, Bulgaria'
                    info3={
                        eventInfo?.data.participantTotalCount ? eventInfo?.data.participantTotalCount + ' PARTICIPANTS' : event?.participants + ' PARTICIPANTS'
                    }
                />
                <HeaderMenu>
                    <HeaderElement title='Information' viewBox='0 1 80 80'>
                        <InformationIcon />
                    </HeaderElement>
                    {/*<HeaderElement title='Schedule' viewBox='0 0 80 80'>*/}
                    {/*    <ScheduleIcon />*/}
                    {/*</HeaderElement>*/}
                    <HeaderElement title='Documents' viewBox='0 1 80 80'>
                        <DocumentIcon />
                    </HeaderElement>
                    <HeaderElement title='Photos' viewBox='1 0.5 19 19'>
                        <PhotoIcon />
                    </HeaderElement>
                    <HeaderElement title='Registration' viewBox='0 0 512 512'>
                        <RegistrationIcon />
                    </HeaderElement>
                    <HeaderElement title='News' viewBox='1 0.5 18 18'>
                        <NewsIcon />
                    </HeaderElement>
                    {/*<HeaderElement title='Livestream' viewBox='0 0 20 20'>*/}
                    {/*    <LivestreamIcon />*/}
                    {/*</HeaderElement>*/}
                    {/*<HeaderElement title='Results' viewBox='0 0 512 512'>*/}
                    {/*    <ResultsIcon />*/}
                    {/*</HeaderElement>*/}
                </HeaderMenu>
            </header>
            <main style={{ marginTop: window.screen.width > 800 ? '13vh' : '4vh' }}>
                <Section title='Information'>
                    {getInfo.map((information: any, index) => (
                        <InformationElement key={index} information={information} inverted={(index + 1) % 2 === 0} />
                    ))}
                </Section>
                {/*<Section title='Schedule'>*/}
                {/*    <Image src='https://ramustkd.org/assets/RSC24-INFO.webp' />*/}
                {/*</Section>*/}
                <Section
                    title='Documents'
                    childrenStyle={{
                        display: 'flex',
                        gap: '1vw',
                        flexDirection: window.screen.width > 900 ? 'row' : 'column',
                    }}
                >
                    {getDocs.map((documentsPerDay: any, index) => (
                        <DocumentsPerDay key={index} documentsPerDay={documentsPerDay}></DocumentsPerDay>
                    ))}
                </Section>
                <Section title='Photos'>
                    <div className='PhotosMain' style={{ display: 'flex' }}>
                        <div style={{ width: window.screen.width > 900 ? 'unset' : '45vw' }}>
                            <p style={{ margin: '3px 0 15px 0', fontSize: window.screen.width > 900 ? 18 : 12 }}>
                                If you are an athlete or a parent/guardian of an athlete who will compete in the 11. Ramus Sofia Cup and want photos like these
                                fill out this{' '}
                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfBPotsj-M7uYo1RCLzo_4-v0rPQI4ErFFUhs9inb6I499n6w/viewform?usp=sf_link'>
                                    form.
                                </a>
                            </p>
                            <img style={{ width: '100%', margin: '0 0 10px 0' }} src='https://sofia-open.com/wp-content/uploads/2024/05/photos.jpg' alt='' />
                            <p style={{ margin: '3px 0 15px 0', fontSize: window.screen.width > 900 ? 18 : 12 }}>Fee: 25 EUR / 10 photos</p>
                            <p style={{ margin: '3px 0 15px 0', fontSize: window.screen.width > 900 ? 18 : 12 }}>
                                Please use only one{' '}
                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfBPotsj-M7uYo1RCLzo_4-v0rPQI4ErFFUhs9inb6I499n6w/viewform?usp=sf_link'>
                                    form
                                </a>{' '}
                                per athlete! Submit a{' '}
                                <a href='https://docs.google.com/forms/d/e/1FAIpQLSfBPotsj-M7uYo1RCLzo_4-v0rPQI4ErFFUhs9inb6I499n6w/viewform?usp=sf_link'>
                                    new form
                                </a>{' '}
                                for another athlete.
                            </p>
                        </div>
                        <a
                            href='https://docs.google.com/forms/d/e/1FAIpQLSfBPotsj-M7uYo1RCLzo_4-v0rPQI4ErFFUhs9inb6I499n6w/viewform?usp=sf_link'
                            style={{ width: window.screen.width > 900 ? 'unset' : '45vw' }}
                        >
                            <img
                                src='https://img001.prntscr.com/file/img001/gq_FOBaVTW-i2Lz4RT9S_w.jpeg'
                                alt='form'
                                style={{
                                    marginLeft: '1vw',
                                    width: window.screen.width > 900 ? 'unset' : '100%',
                                    height: window.screen.width > 900 ? '60vh' : '100%',
                                    objectFit: window.screen.width > 900 ? 'unset' : 'contain',
                                }}
                            />
                        </a>
                    </div>
                </Section>
                <Section id='registration' sectionStyle={{ width: '100%', maxWidth: '100vw', margin: 0 }}>
                    <Registration href='https://tpss2021.eu' />
                </Section>
                <NewsElement />
                {/*<Section*/}
                {/*    title='Livestream'*/}
                {/*    childrenStyle={{*/}
                {/*        display: 'grid',*/}
                {/*        gridTemplateColumns: window.screen.width > 900 ? 'repeat(auto-fit, minmax(300px, 1fr))' : 'repeat(auto-fit, minmax(150px, 1fr))',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    {getLive.map((livestream: any, index) => (*/}
                {/*        <ImageWithScale*/}
                {/*            href={`https://www.youtube.com/watch?v=${livestream.href}`}*/}
                {/*            src={livestream.type === 'ranked' ? 'https://ramustkd.org/assets/MatOctagon.png' : 'https://ramustkd.org/assets/MatSquare.png'}*/}
                {/*            title={`Court №${index + 1}`}*/}
                {/*            alt={`Court №${index + 1}`}*/}
                {/*            key={index}*/}
                {/*            noCopyright*/}
                {/*        />*/}
                {/*    ))}*/}
                {/*</Section>*/}
            </main>
            <Footer />
        </>
    );
}
